<template>
  <div class="videoTutorial">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>视频教程</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div class="left">
        <div
          v-for="(item,index) in tabs"
          :key="index"
          class="left_item"
          @click="choose(index,item)"
          :class="item.active?'active':''"
        >
          <div class="left_item_title">
            <div>{{item.title}}</div>
            <svg
              v-if="item.active" width="16" height="16"
              viewBox="-50 -50 300 300"
              style="transform: rotate(90deg);"
              ref="video"
            >
              <polygon class="triangle" stroke-linejoin="round" points="100,0 0,200 200,200"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="right_title">视频教程：{{ title }}</div>
        <video width="100%" height="100%" controls id="showVideo">
          <source src="http://po-o-cn.oss-cn-shenzhen.aliyuncs.com/upload/video/PC/policyLiberal.mp4" type="video/mp4">
        </video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoTutorial',
  data () {
    return {
      tabs: [
        {
          title: '政策文库',
          videoUrl: 'http://po-o-cn.oss-cn-shenzhen.aliyuncs.com/upload/video/PC/policyLiberal.mp4',
          active: true
        },
        {
          title: '政策词典',
          videoUrl: 'http://po-o-cn.oss-cn-shenzhen.aliyuncs.com/upload/video/PC/policyDict.mp4',
          active: false
        },
        {
          title: '项目匹配',
          videoUrl: 'http://po-o-cn.oss-cn-shenzhen.aliyuncs.com/upload/video/PC/projectMatch.mp4',
          active: false
        },
        {
          title: '智能推送',
          videoUrl: 'http://po-o-cn.oss-cn-shenzhen.aliyuncs.com/upload/video/PC/intellectPush.mp4',
          active: false
        },
        {
          title: '申报管家',
          videoUrl: 'http://po-o-cn.oss-cn-shenzhen.aliyuncs.com/upload/video/PC/declareManage.mp4',
          active: false
        }
      ],
      select: '',
      title: '政策文库'
    };
  },
  methods: {
    choose (index, item) {
      this.tabs.forEach((v, i) => {
        i === index ? v.active = true : v.active = false;
      });
      document.getElementById('showVideo').src = item.videoUrl;
      this.title = item.title;
    }
  }
};
</script>

<style lang="less" scoped>
  .videoTutorial{
    background-color: #fff;
    padding: 45px 55px;
    .content{
      margin-top: 40px;
      display: flex;
      .left{
        .left_item{
          padding: 15px 18px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          // >div:nth-child(1){
          //   margin-right: 18px;
          // }
          .left_item_title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            >div:nth-child(1){
              margin-right: 60px;
            }
            .triangle {
              fill: #156ED0;;
              stroke: #156ED0;;
              stroke-width: 60; // 圆角角度
              // transform: rotate(90deg);
            }
          }
        }
      }
      .right{
        flex: 1;
        margin-left: 25px;
        .right_title {
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }
  .active{
    background: #EBF3FF;
    border-radius: 5px;
    color: #156ED0;
  }
</style>
